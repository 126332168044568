@media print {

  html,
  body {
    display: none;
  }
}

* {
  user-select: none;
}

:focus-visible {
  outline: none;
}

ion-content {
  --background: #fff !important;
}

ion-content.transparentIonContent,
ion-toolbar.transparentIonContent {
  --background: transparent !important;
}

ion-modal.transparentIonContent {
  --background: rgb(0 0 0 / 50%) !important;
}

#background-content {
  background: #f3f3f3 !important;
}

.padding-box {
  padding: 15px;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.color-white {
  color: #fff;
}

.title-txt {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.sub-title {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 7px;
  margin-top: 0;
  background-color: #00000066;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.download-title {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 7px;
  background-color: #00000066;
  padding: 5px;
  position: absolute;
  bottom: 198px;
  right: 10px;
}

.inner-title {
  margin-bottom: 20px;
  text-transform: capitalize;
  color: #121212;
  font-size: 14px;
  font-weight: 800;
  margin-top: 5px;
}

.center-title {
  color: #121212;
  font-size: 14px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

.link-title {
  font-size: 13px;
  color: #fa4939;
  text-decoration: underline;
  font-weight: 600;
  margin-right: 10%;
}

.line-text {
  color: #52adf5 !important;
  text-decoration: underline;
}

.flex-head {
  display: none;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-head .inner-title {
  font-weight: 600;
  margin-left: 11px;
}

.flex-head .inner-title strong {
  display: none;
}

.bg-grey {
  /* background-color: #f3f3f3; */
}

.globalSearchModal {}

.globalSearchModal input {
  width: 100%;
  border: 0;
  background: rgb(255 255 255 / 40%);
  height: 50px;
  border-radius: 15px;
  padding: 10px 72px 10px 45px;
}

.globalSearchModal>svg,
.globalSearchModal button,
.globalSearchModal ion-icon {
  position: absolute;
}

.globalSearchModal svg {
  height: auto !important;
}

.globalSearchModal ion-icon {
  right: 45px;
  font-size: 20px;
}

.globalSearchModal>svg {
  left: 10px;
}

.globalSearchModal button {
  right: 12px;
  background: transparent;
}

.border-radius {
  border-radius: 25px;
}

.t-bar {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  border-radius: 0 0 20px 20px;
  box-shadow: rgb(136 136 136 / 28%) 0px 4px 6px;
  --min-height: 75px;
}

.visibilityHidden {
  visibility: hidden;
}

.placeList {
  margin: 0 20px;
}

.placeList ul {
  padding: 0;
  list-style: none;
}

.placeList ul li {
  padding: 22px 20px;
  background: #fff;
  box-shadow: 0px 2px 12px 1px rgb(0 0 0 / 7%);
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.placeList ul li label {
  font-weight: 500;
}

.placeList ul li:not(:first-child) {
  margin-top: 10px;
}

.placeList ul li label:last-child {
  display: flex;
  flex: 1;
  padding-left: 8px;
  align-items: center;
}

.large-header {
  background: rgb(112 112 112 / 20%);
  height: 153px;
  border-radius: 0 0 20px 20px;
  padding: 20px 20px;
}

.large-header h2 {
  padding-left: 18px;
  font-weight: 900;
  font-size: 30px;
  margin-top: 25px;
}

.transparentToolbar.t-bar {
  --background: transparent;
  position: absolute;
}

.transparentToolbar.t-bar .top-nav {
  background-color: transparent;
}

.s-bar .searchbar-input-container {
  height: 41px;
}

.s-bar .searchbar-input {
  background-color: #fff;
  font-size: 14px;
  border-radius: 10px;
}

div#inbox-list {
  border-bottom: 0;
  padding: 20px;
  overflow-y: auto;
}

div#inbox-list .ion-item {
  padding: 8px 0;
  display: flex;
  align-items: center;
  min-height: 64px;
}

div#inbox-list .ion-item:last-child {
  padding: 18px 0 8px;
}

div#inbox-list .ion-item ion-label {
  flex: 1;
  font-weight: 500;
  font-weight: 600;
  font-family: sans-serif !important;
  font-size: 14px !important;
  color: #1a1a1a !important;
}

.dropDownMenuWrap {
  position: relative;
}

.dropDownMenu {
  position: absolute;
  right: 0;
  background: #fff;
  box-shadow: 0px 0px 14px 2px rgb(0 0 0 / 10%);
  border-radius: 20px;
  width: 155px;
  z-index: 9;
}

.dropDownMenu ul {
  list-style: none;
  padding: 10px 0;
  margin: 0;
}

.dropDownMenu ul li {
  padding: 15px 24px;
  font-weight: 500;
  font-size: 14px;
}

.coverArea {
  background: #e87623 url("../images/profileCoverGraphics.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 25px;
  min-height: 200px;
}

.ratingStar span {
  font-size: 20px !important;
}

.profileDetails {
  display: flex;
  align-items: flex-start;
}

.profileDetails .profile-pic-container {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #ccc;
  display: flex;
}

.profileDetails .profile-pic-container img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.edit-button {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(255 255 255);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 8px;
  width: 36px;
  height: 36px;
}

.edit-button img {
  width: 16px !important;
  height: 16px !important;
}

.profileDetails .name {
  padding-left: 15px;
  padding-top: 10px;
  flex: 1;
}

.profileDetails .name h3,
.profileDetails .name h2,
.profileDetails p {
  color: #fff;
}

.profileDetails .name h3 {
  margin: 0;
  font-weight: 100;
  font-size: 16px;
}

.profileDetails .name h2 {
  margin: 0;
  font-size: 30px;
  font-weight: 600;
}

.profileDetails p {
  margin-bottom: 0;
}

.profile-additional-options {}

.profile-additional-options ul {
  padding: 40px 25px 0;
  margin: 0;
}

.profile-additional-options ul li {
  display: flex;
  align-items: center;
  padding: 16px 0px;
}

.profile-additional-options ul li:not(:first-child) {
  border-top: 1px solid #ccc;
}

.profile-additional-options ul li .option-menu-icon {
  margin-right: 25px;
  display: inline-flex;
}

.profile-additional-options ul li .option-menu-icon img {
  width: 20px;
}

.profile-additional-options ul li .option-menu-label {
  font-weight: 500;
  font-size: 14px;
}

.profile-additional-options .fields label {
  color: #000;
}

.profile-additional-options .fields label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
}

.profile-additional-options .fields input {
  border-radius: 8px;
  border: 0;
  background: #e0e0e0;
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
}

.version {
  text-align: center;
  color: #777777;
  padding: 10px 0;
  font-weight: 500;
  font-size: 14px;
}

.card {
  background: #fff;
  text-align: center;
  border-radius: 25px;
  padding: 10px 0;
  margin-top: 25px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blue-bg {
  background-color: #52adf5;
}

.orng-bg {
  background-color: #ffbb56;
}

.pink-bg {
  background-color: #f57ea6;
}

.purple-bg {
  background-color: #a981f2;
}

.green-bg {
  background-color: #6de382;
}

.pos-box {
  position: relative;
}

.home-sec .border-radius {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
}

.info-sec .card {
  padding: 0;
}

.info-sec .title-txt {
  margin-top: 0;
}

.fil-icon {
  background: #fff;
  width: 10%;
  height: 10%;
  border-radius: 5px;
  padding: 5px 5px 0;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-box {
  width: 100%;
}

.form-box .text-input {
  margin-bottom: 20px;
  border-bottom: 1px solid #feeded;
}

ion-button.yelow-btn.ios.button.button-solid.ion-activatable.ion-focusable {
  --background: #ffdb66 !important;
  color: #000 !important;
}

.form-box .text-input .MuiInput-root.MuiInput-underline {
  border: 1px solid #000;
  border-radius: 10px;
  padding: 2px 10px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.1);
}

.form-box .text-input .MuiInput-root.MuiInput-underline::before {
  border-bottom: 0;
  border-left: 1px solid #000;
  bottom: 11px;
  left: 37px;
}

.form-box .text-input #input-with-icon-textfield {
  padding-left: 7px;
  height: 2em;
  color: #fff;
}

.form-box .text-input svg.MuiSvgIcon-root {
  color: #fff;
  font-size: 20px;
}

.MuiButton-root.MuiButton-Primary.white-btn {
  border: 1px solid #fff;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  background: #fff;
  padding: 8px;
  color: #121212 !important;
}

.zoom-btn {
  position: absolute;
  bottom: 30px;
  right: 20px;
  background: #fa4939;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px 10px;
  border-radius: 30px;
  -webkit-box-shadow: 4px 12px 22px rgba(255, 0, 0, 0.3);
  box-shadow: 4px 12px 22px rgba(255, 0, 0, 0.3);
}

.zoom-btn img {
  width: 23px;
}

.zoom-btn img:first-child {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 6px;
}

.zoom-btn img:last-child {
  padding-top: 6px;
}

.top-logo {
  position: relative;
  left: 0;
  right: 0;
  top: 50px;
  text-align: center;
  margin: auto;
}

.top-logo img {
  width: 45%;
}

.top-logo h3 {
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
}

.login-sec {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4%;
  width: 90%;
  margin: auto;
  background-color: rgba(237, 108, 0, 0.8) !important;
  padding: 20px;
}

.wh-txt {
  color: #fff !important;
}

.login-sec span.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary {
  color: #ffdb66 !important;
}

ion-button.yelow-btn.md.button.button-solid {
  --background: #ffdb66 !important;
  color: #000 !important;
}

.login-sec h4 {
  color: #fff;
  font-size: 15px;
  margin-bottom: 15px;
}

.login-bg::part(background) {
  background-image: url(../images/logIn-register.jpg);
  /* position: relative; */
  /* background-position: 100% 58%; */
  background-repeat: no-repeat;
  background-size: 100%;
  /* width: 100%; */
  height: 100%;
}

.top-nav {
  /* background-color: #FA4939; */
  padding: 5px 20px;
}

.nav_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
}

.top-nav svg.MuiSvgIcon-root {
  height: 50px;
}

.top-banner {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 0 !important;
  margin-top: -20px !important;
}

.top-banner img {
  width: 100%;
}

.top-banner .banr-box {
  text-align: center;
}

.map-sec .top-banner .banr-box {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}

.map-sec .top-banner .banr-box .title-txt {
  margin-top: 0;
}

.map-sec .s-bar {
  position: absolute;
  bottom: -27px;
}

.map-sec .s-bar .searchbar-input-container {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.caros-card {
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  height: 100%;
  width: 100%;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.dis_card {
  border-radius: 3px !important;
  width: 94% !important;
}

.caros-card img {
  width: 75%;
  height: auto;
}

.caros-card h5 {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.logincard {
  position: relative;
  text-align: center;
  border-radius: 15px;
  /* -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); */
  overflow: hidden;
  width: 33.333%;
  height: 145px;
}

.logincard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px !important;
}

.caros-card1 {
  cursor: pointer;
  position: relative;
  text-align: center;
  border-radius: 10px;
  padding-top: 100%;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.caros-card1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  /* background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, rgb(0 0 0 / 37%) 60%, rgb(0 0 0 / 88%) 100%); */
  z-index: 1;
  opacity: 0.5;
}

.caros-card1:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  /* background: linear-gradient(315deg, rgb(255 255 255 / 0%) 0%, rgb(0 0 0 / 27%) 60%, rgb(0 0 0 / 79%) 100%); */
}

.caros-card1 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px !important;
}

.caros-card1 h5 {
  position: absolute;
  top: 20px;
  left: 0;
  padding: 12px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  text-align: left;
  margin: 0;
  z-index: 2;
  background: rgb(0 0 0 / 30%);
}

.caros-card1 h6 {
  font-size: 12px;
  font-weight: 500;
  color: #fff;

  margin: 0;
}

.filterSlider {
  position: relative;
}

/* .filterSlider:after {
  content: '';
  position: absolute;
  right: -10px;
  width: 65px;
  bottom: -2px;
  top: -2px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243 243 243 / 95%) 60%, rgb(243 243 243) 100%);
  z-index: 1;
  backdrop-filter: blur(0px);
} */

.home-carousel2 {
  padding: 25px 20px 0 20px;
  width: 100%;
}

.home-carousel2 .react-multi-carousel-item {
  height: 240px !important;
  transition: 300ms transform;
}

.neverBefore {
  text-align: center;
  padding-top: 20px;
}

.neverBefore img {
  max-width: 200px;
}

.home-carousel2 .swiper-slide {
  /* height: 240px !important; */
  transition: 300ms transform;
}

.home-carousel2 .swiper-slide h5 {
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 0;
}

.home-carousel3 .swiper-slide {
  /* margin-bottom: 5px; */
  /* height: 270px !important; */
  transition: 300ms transform;
  margin: 0 10px;
  /* width: 150px; */
}

.home-carousel3 .swiper-slide>div {
  /* height: 100%; */
}

.home-carousel3 .swiper {
  /* overflow: visible !important;
  transform: translateY(-50px); */
}

.home-carousel3 .swiper-slide-active {
  /* transform: translateY(-45px) !important; */
}

.our-favourites {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 20px;
  font-weight: 600;
}

.home-carousel3 {
  background: #e4e4e4;
  padding: 25px 20px 15px 20px;
  /* margin-top: 115px; */
}

.home-carousel3>div:not(.tagLine) {
  /* display: flex;
  justify-content: center; */
  /* transform: translateY(-50px); */
}

.home-carousel3 .caros-card1 {
  border-radius: 50%;
  /* margin-right: 15px; */
}

.home-carousel3 .caros-card1:after,
.home-carousel3 .caros-card1:before {
  display: none;
}

.home-carousel3 .card-caption {
  width: 100%;
}

.home-carousel3 .card-caption h5 {
  position: relative;
  margin-top: 8px;
  text-align: center;
  width: 100%;
  padding: 0;
  color: #000;
  font-size: 10px;
}

.home-carousel3 .card-caption span {
  color: #fff;
  font-size: 10px !important;
}

.tagLine {
  color: #073c48;
  text-align: left;
  padding-right: 10px;
  max-width: 330px;
  margin: 40px auto 0;
}

.tagLine p {
  margin: 0;
  font-size: 20px !important;
}

.tagLine p:last-child {
  text-align: right;
}

.tagLine strong {
  font-size: 38px;
  font-weight: 900;
}

.locationChoose {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0f0f0;
  padding: 12px 0;
}

.locationChoose .locationChooseLabel {
  max-width: 300px;
  min-width: 250px;
  display: flex;
  padding: 7px 20px;
  border-radius: 32px;
  font-weight: 700;
  background: #ffffff;
  color: #000000;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 14px !important;
  cursor: pointer;
  box-shadow: 0px 0px 20px 1px rgb(71 71 71 / 10%);
}

.locationChoose .locationChooseLabel p {
  font-size: 12px !important;
  margin: 5px 0 0;
}

.map-carousel {
  background: #fff;
  border-top-right-radius: 60px;
}

.map-carousel .padding-box {
  padding-right: 0;
  width: 120%;
}

.map-carousel .react-multi-carousel-item {
  margin-bottom: 10px;
  margin-top: 5px;
}

.map-carousel .caros-card {
  width: 90%;
  background-color: #fff;
  -webkit-box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.2);
  padding: 0;
  margin-left: 5px;
}

.map-carousel .caros-card img {
  width: 100%;
  height: 100px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.map-carousel .caros-card h5 {
  color: #121212;
}

.home-carousel {
  padding: 20px 0 0 15px;
  width: 118%;
}

.home-carousel .react-multi-carousel-item {
  margin-bottom: 5px;
}

.place-carousel .padding-box {
  /* padding-right: 0; */
}

.place-carousel .caros-card {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  padding: 0;
  text-align: left;
}

.place-carousel .caros-card h4 {
  color: #121212;
  font-size: 15px;
  margin-top: 0px;
  margin-left: 0px;
  font-weight: 600;
}

.place-carousel .caros-card img {
  width: 100%;
  height: 247px;
  border-radius: 25px;
  margin-bottom: 25px;
}

.place-carousel .caros-card h5 {
  color: #121212;
  font-size: 13.9px;
  text-align: left;
  font-weight: 600;
}

.place-carousel .caros-card p {
  font-size: 12px;
  text-align: justify;
  line-height: 1.5;
}

.place-carousel .react-multi-carousel-list {
  width: 187%;
}

.place-carousel .react-multi-carousel-dot-list {
  bottom: unset;
  top: 35%;
  left: 22%;
  right: unset;
}

.place-carousel .react-multi-carousel-dot button {
  border: 0;
  background: #ccc;
  width: 9px;
  height: 9px;
}

.place-carousel .react-multi-carousel-dot--active button {
  background: #fa4939;
}

.view-carousel .caros-card {
  background-color: #fff;
  padding: 0;
  margin-left: 5px;
  border-radius: 25px;
}

.view-carousel .caros-card img {
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 120px;
}

.view-carousel .caros-card h5 {
  color: #121212;
  text-align: left;
  font-size: 13px;
  margin: 10px 20px 7px;
  font-weight: 600;
}

.view-carousel .caros-card .flex-head {
  margin: 0 15px 15px;
}

.view-carousel .caros-card .MuiSvgIcon-root {
  color: #fa4939;
  font-size: 18px;
}

.view-carousel .caros-card span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.view-carousel .caros-card span h6 {
  font-size: 12px;
  margin: 0;
  line-height: 1.6;
}

.view-carousel .padding-box {
  padding-right: 0;
}

.view-carousel .react-multi-carousel-item {
  margin-bottom: 10px;
}

.view-carousel .react-multi-carousel-list {
  width: 170%;
}

.table-info th.MuiTableCell-root,
.table-info td.MuiTableCell-root {
  font-size: 12px;
  text-align: left;
  border-bottom: 0;
  padding: 16px 12px;
}

.table-info th.MuiTableCell-root:not(:last-child),
.table-info td.MuiTableCell-root:not(:last-child) {
  border-right: 0.9px solid #e0e0e0;
}

.img-card .inner-title {
  margin-top: 10px;
  margin-bottom: 20px;
}

.img-card .caros-card {
  border-radius: 30px !important;
  margin-left: 0;
  background-color: #fff;
  padding: 0;
  width: 100%;
  /* -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  box-shadow: none;
}

.img-card .caros-card img {
  object-fit: cover;
  width: 100%;
  /* height: 175px; */
  border-radius: 30px !important;
}

.floatingFab {
  background: #fff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 18px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
}

ion-fab {
  right: calc(20px + var(--ion-safe-area-right, 0px));
  bottom: 20px;
}

.rightMenu {
  width: 304px;
  position: fixed;
  background: #fff;
  height: 100%;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 9999;
  overflow-y: auto;
  transform: translateX(100%);
  transition: 300ms transform;
}

.rightMenu.visible {
  transform: none;
  box-shadow: rgba(0, 0, 0, 0.18) -4px 0px 16px;
}

.rightMenu .linksItem {
  padding: 22px 20px;
  background: #fff;
  box-shadow: 0px 2px 12px 1px rgb(0 0 0 / 7%);
  border-radius: 20px;
}

.rightMenu .linksItem:not(:first-child) {
  margin-top: 10px;
}

.overlayMenu {
  position: fixed;
  background: #000000;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0.5;
}

.ripple-effect {
  animation: ripple-animation 1.5s ease-out;
}

.verifyLogin {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

@keyframes ripple-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(232, 118, 35, 0.3);
  }

  100% {
    box-shadow: 0 0 0 40px rgba(232, 118, 35, 0);
  }
}

.floatingFab p {
  margin: 0;
  font-size: 10px !important;
  font-weight: 500;
}

.img-card .caros-card:not(.itinerary) img {
  height: 210px;
}

.img-card .caros-card h5 {
  color: #121212;
  text-align: left;
  font-size: 18px;
  margin: 10px 25px 8px;
  font-weight: 600;
}

.img-card .caros-card .flex-head {
  padding-bottom: 10px;
  margin: 0 10px 18px;
}

.img-card .caros-card span {}

.img-card .caros-card span h6 {
  font-size: 12px;
  margin: 0;
  line-height: 1.6;
}

.img-card .caros-card .MuiSvgIcon-root {
  color: #fa4939;
  font-size: 16px;
  margin-right: 3px;
  position: relative;
}

.img-gallery .MuiImageListItem-img {
  border-radius: 16px;
}

.img-gallery .MuiImageListItemBar-root {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  text-align: center;
}

.img-gallery .MuiImageListItemBar-titleWrap {
  padding: 3px;
}

.img-gallery .MuiImageListItemBar-title {
  font-size: 12px;
}

.img-gallery .MuiImageListItemBar-subtitle {
  font-size: 10px;
}


.d_card {
  border-radius: 30px;
  background: white;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  margin: 15px 0px;
  width: 100%;
  box-shadow: 0px 2px 12px 1px rgb(0 0 0 / 7%);
}

.d_card h4 {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  font-family: sans-serif;
  margin-top: 0;
}

.d_card p {
  font-size: 0.875rem;
  font-weight: 500;
  /* text-align: left !important; */
  font-family: sans-serif;
  color: #141414;
  line-height: 18px;
  background: transparent !important;
  letter-spacing: 0.1px;
}

.d_card p span {
  background: transparent !important;
}

.twoCols {
  width: 48%;
}

.twoCols .text-card {
  display: flex;
}

.twoCols .text-card img {
  margin-bottom: 10px;
  width: 35px;
  height: 35px;
}

.bg-grey .twoCols.rSpace {
  margin-right: 2%;
}

.bg-grey .twoCols.lSpace {
  margin-left: 2%;
}

.react-multi-carousel-dot-list {
  position: absolute;
  z-index: 1;
  bottom: 5px !important;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

/* .react-multi-carousel-item {
  width: 90px !important;
} */

.card_margin_bottom {
  margin-bottom: 15px;
  margin-left: 13px;
  margin-right: 13px;
}

.desc-box {
  margin: 10px 15px;
  color: black;
  height: 80px;
  overflow: auto;
}

.desc-box p {
  font-size: 10px;
  text-align: left;
  padding-bottom: 10px;
  margin: 0;
}

.image_of_sidebar {
  background-color: #cbcbcb;
  box-shadow: 2px 3px 0px 0px #e3e3e3;
  /* margin: 10px; */
  margin-top: 2px;
  margin-right: 12px;
  margin-bottom: 2px;
  margin-left: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.sidebar_img_crop {}

.viewmore_btn {
  font-size: 12px !important;
  text-transform: capitalize;
}

.historyArea,
.Overview {
  background: #dfdfdf !important;
  padding: 20px !important;
  margin-top: 0;
}

.historyArea.d_card h4 {
  padding-left: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}

.twoCols.d_card p {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 12px !important;
  font-weight: 600;
  /* word-break: break-all; */
}

.twoCols.d_card p span {
  font-size: 12px !important;
}

.districtName {
  margin-top: 0;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}

.districtName h4 {
  background: #e87623;
  margin: 0;
  color: #fff;
  padding: 8px 14px;
  font-size: 12px;
  border-radius: 0 0 6px 6px;
  line-height: 1.1;
  font-weight: 300;
}

.rsm-svg {
  border: none !important;
  outline: none !important;
}

.logo_ctr {
  text-align: center;
  background-color: white;
}

.logo_width {
  width: 120px;
}

.all_right {
  font-weight: 600;
  font-family: sans-serif !important;
  font-size: 14px !important;
  color: #1a1a1a !important;
}

.wrap-head {
  padding-bottom: 0;
  margin-bottom: 0;
  margin-left: 14px;
  margin-right: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.d_card[class*="Waterfall"],
.d_card.HillStations,
.d_card.ReligiousPlaces,
.d_card.Beaches,
.d_card.Islands,
.d_card.Wildlife,
.d_card.UNESCOHeritageSites,
.d_card.HistoricalPlaces,
.d_card.ImportantPlaces,
.d_card.Sports,
.d_card.EducationalInst,
.d_card[class*="Restaurant"],
.d_card[class*="Hotel"],
.d_card.CarRentals,
.d_card.BarsClubs,
.d_card.StateMap,
.d_card.ThingsToDo {
  padding: 0;
  background: transparent;
  box-shadow: none;
}

.d_card[class*="Restaurant"] p {
  margin: 10px 0 0 !important;
}

.d_card[class*="Restaurant"] h5+p {
  display: flex !important;
  margin: 0 0 !important;
}

.d_card[class*="Waterfall"] img,
.d_card.HillStations img,
.d_card.ReligiousPlaces img,
.d_card.Beaches img,
.d_card.Islands img,
.d_card.Wildlife img,
.d_card.UNESCOHeritageSites img,
.d_card.HistoricalPlaces img,
.d_card.ImportantPlaces img,
.d_card.Sports img,
.d_card.EducationalInst img,
.d_card.StateMap img {
  border-radius: 30px;
}

.d_card[class*="Waterfall"] .carousel-list,
.d_card.HillStations .carousel-list,
.d_card.ReligiousPlaces .carousel-list,
.d_card.Beaches .carousel-list,
.d_card.Islands .carousel-list,
.d_card.Wildlife .carousel-list,
.d_card.UNESCOHeritageSites .carousel-list,
.d_card.HistoricalPlaces .carousel-list,
.d_card.ImportantPlaces .carousel-list,
.d_card.Sports .carousel-list,
.d_card.EducationalInst .carousel-list,
.d_card .forMbl .carousel-list {
  padding-bottom: 15px;
}

.d_card[class*="Waterfall"] .react-multi-carousel-dot-list,
.d_card.HillStations .react-multi-carousel-dot-list,
.d_card.ReligiousPlaces .react-multi-carousel-dot-list,
.d_card.Beaches .react-multi-carousel-dot-list,
.d_card.Islands .react-multi-carousel-dot-list,
.d_card.Wildlife .react-multi-carousel-dot-list,
.d_card.UNESCOHeritageSites .react-multi-carousel-dot-list,
.d_card.HistoricalPlaces .react-multi-carousel-dot-list,
.d_card.ImportantPlaces .react-multi-carousel-dot-list,
.d_card.Sports .react-multi-carousel-dot-list,
.d_card.EducationalInst .react-multi-carousel-dot-list {
  bottom: 0px !important;
}

.d_card[class*="Waterfall"] .img-card .caros-card,
.d_card.HillStations .img-card .caros-card,
.d_card.ReligiousPlaces .img-card .caros-card,
.d_card.Beaches .img-card .caros-card,
.d_card.Islands .img-card .caros-card,
.d_card.Wildlife .img-card .caros-card,
.d_card.UNESCOHeritageSites .img-card .caros-card,
.d_card.HistoricalPlaces .img-card .caros-card,
.d_card.ImportantPlaces .img-card .caros-card,
.d_card.Sports .img-card .caros-card,
.d_card.EducationalInst .img-card .caros-card {
  border-radius: 30px !important;
  background-color: transparent;
  box-shadow: none;
}

.d_card[class*="Waterfall"] .img-card .caros-card img,
.d_card.HillStations .img-card .caros-card img,
.d_card.ReligiousPlaces .img-card .caros-card img,
.d_card.Beaches .img-card .caros-card img,
.d_card.Islands .img-card .caros-card img,
.d_card.Wildlife .img-card .caros-card img,
.d_card.UNESCOHeritageSites .img-card .caros-card img,
.d_card.HistoricalPlaces .img-card .caros-card img,
.d_card.ImportantPlaces .img-card .caros-card img,
.d_card.Sports .img-card .caros-card img,
.d_card.EducationalInst .img-card .caros-card img {
  border-radius: 30px !important;
}

.d_card[class*="Waterfall"] h4,
.d_card.HillStations h4,
.d_card.ReligiousPlaces h4,
.d_card.Beaches h4,
.d_card.Islands h4,
.d_card.Wildlife h4,
.d_card.UNESCOHeritageSites h4,
.d_card.HistoricalPlaces h4,
.d_card.ImportantPlaces h4,
.d_card.Sports h4,
.d_card.EducationalInst h4,
.d_card[class*="Restaurant"] h4,
.d_card.OptionalSights h4,
.d_card.LocalFestivals h4,
.d_card[class*="Hotel"] h4,
.d_card.CarRentals h4,
.d_card.BarsClubs h4,
.d_card.Reviews h4,
.d_card.StateMap h4,
.d_card.ThingsToDo h4 {
  padding-left: 25px;
}

.d_card[class*="Restaurant"] h4,
.d_card[class*="Hotel"] h4,
.d_card.CarRentals h4,
.d_card.BarsClubs h4 {
  margin-bottom: 0;
}

.d_card.ThingsToDo .react-multi-carousel-item>div {
  margin: 0px 6px;
}

.d_card.ThingsToDo .react-multi-carousel-item img {
  height: 80px !important;
  border-radius: 50%;
}

.d_card.d_card.ThingsToDo .react-multi-carousel-dot-list {
  display: none;
}

.d_card.d_card.ThingsToDo h6 {
  font-size: 14px;
}

.d_card[class*="Restaurant"] .inner_slide_card,
.d_card[class*="Hotel"] .inner_slide_card,
.d_card.CarRentals .inner_slide_card,
.d_card.BarsClubs .inner_slide_card {
  border-radius: 30px;
  background: white;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  box-shadow: 0px 2px 12px 1px rgb(0 0 0 / 7%);
}

.d_card[class*="Restaurant"] .swiper,
.d_card[class*="Hotel"] .swiper,
.d_card.CarRentals .swiper,
.d_card.BarsClubs .swiper {
  padding: 10px 10px 35px;
  margin-right: -10px;
  margin-left: -10px;
}

.d_card[class*="Restaurant"] .react-multi-carousel-dot-list,
.d_card[class*="Hotel"] .react-multi-carousel-dot-list,
.d_card.CarRentals .react-multi-carousel-dot-list,
.d_card.BarsClubs .react-multi-carousel-dot-list {
  bottom: 0 !important;
}

.d_card[class*="Restaurant"] .react-multi-carousel-list,
.d_card[class*="Hotel"] .react-multi-carousel-list,
.d_card.CarRentals .react-multi-carousel-list,
.d_card.BarsClubs .react-multi-carousel-list {
  padding-bottom: 40px;
}

.d_card.OptionalSights,
.d_card.LocalFestivals,
.d_card.Reviews {
  border-radius: 30px;
  background: transparent;
  padding: 0;
  box-shadow: none;
}

.d_card.OptionalSights .swiper,
.d_card.LocalFestivals .swiper {
  border-radius: 30px;
  background: white;
  width: 100%;
  box-shadow: 0px 2px 12px 1px rgb(0 0 0 / 7%);
}

.d_card.Reviews .out_card>.swiper {
  border-radius: 30px;
  background: white;
  width: 100%;
  box-shadow: 0px 2px 12px 1px rgb(0 0 0 / 7%);
}

.d_card.OptionalSights .swiper .swiper-slide,
.d_card.LocalFestivals .swiper .swiper-slide {
  padding: 20px 20px 38px;
}

.d_card.Reviews .out_card>.swiper .swiper-slide {
  padding: 20px 20px 38px;
}

.d_card .out_card {}

.languageDropdown {
  cursor: pointer;
  border: 1px solid rgb(204 204 204 / 75%);
  padding: 9px;
  width: 122px;
  font-size: 12px;
  border-radius: 5px;
  background-color: rgb(255 255 255 / 75%);
}

.d_card.Reviews .userIcon {
  min-width: 50px;
  max-width: 50px;
  width: 50px;
}

.d_card.Reviews .reviewContentRight {
  width: calc(100% - 50px);
}

.languageDropdown:focus {
  outline: none;
}

.inner_slide_card .userIcon img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 18px 2px;
}

.allReviewModal {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  color: #e87623;
}

.allReviewModal strong {
  font-family: sans-serif;
}

.reviewBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.reviewBox>img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 18px 2px;
}

.reviewBox .ratingStar span {
  font-size: 45px !important;
}

.reviewInputArea {
  width: 100%;
  position: relative;
}

.reviewInputArea ion-textarea {
  padding-right: 30px;
  border-bottom: 1px solid #000;
}

.reviewImgs {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
  max-height: 230px;
  overflow-y: auto;
}

.reviewImgs .imgItem {
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  padding-top: calc(33% - 6px);
  width: calc(33% - 6px);
}

.footerMenu {
  z-index: 1;
  border-radius: 26px 26px 0 0;
  padding: 10px;
  background: #fff;
  box-shadow: 0px -3px 12px 0px rgb(0 0 0 / 20%);
  padding-bottom: env(safe-area-inset-bottom);
}

.footerMenu-wrap {
  display: flex;
  justify-content: space-around;
}

.footerMenu-wrap .f-menu-item {
  text-align: center;
  min-width: 70px;
}

.footerMenu-wrap .f-menu-item ion-menu-button {
  display: block;
  width: 25px;
  height: 30px;
  margin: 0 auto;
  --padding-end: 0;
  --padding-start: 0
}

.footerMenu-wrap .f-menu-item img {
  height: 25px;
}

.footerMenu-wrap .f-menu-item p {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 10px !important;
  font-weight: 600;
}

/* login */

.login-page .login-bg {
  background-image: url(../images/newLoginBanner.png);
  position: relative;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
}

.login-page .map-bg {
  background-image: url(../images/newLoginBanner.png);
}

.login-page .form-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 90%;
}

.login-page .form-wrap .login-sec h4 {
  color: #fff;
  font-size: 13px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 13px;
}

.login-page .form-wrap ion-input.form-control {
  border-bottom: 1px solid #fff;
  color: #fff;
  font-size: 12px;
}

.login-page .form-wrap ion-label {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.login-page .form-wrap .form-grp {
  margin-bottom: 10px;
}

.roundedbtn {
  border: 0;
  text-transform: capitalize;
  font-size: 14px;
  border-radius: 20px;
  background: #253e49;
  padding: 10px;
  color: #fff !important;
  font-weight: 700;
  width: 160px;
}

.login-page .MuiButton-root.MuiButton-Primary.login-btn {
  border: 0;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  background: #3f3fde;
  padding: 8px;
  color: #fff !important;
  width: 100%;
}

.log-mod .MuiDialog-paper {
  background-color: #f9a900;
}

.log-mod .MuiDialogTitle-root {
  color: #000;
  font-size: 15px;
}

.log-mod .MuiButton-root.MuiButton-Primary.modal-btn {
  border: 0;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  background: #3f3fde;
  padding: 8px;
  color: #fff !important;
}

.log-mod .form-box .text-input #input-with-icon-textfield {
  padding-left: 7px;
  height: 1.5em;
  color: #000;
}

.log-mod .form-box .text-input .MuiInput-root.MuiInput-underline::before {
  border-bottom: 0;
  border-left: 1px solid #000;
  bottom: 0;
  left: 36px;
  top: 0;
  margin-top: 6px;
  margin-bottom: 6px;
}

.log-mod .form-box .text-input svg.MuiSvgIcon-root {
  color: #000;
  font-size: 18px;
}

.log-mod .form-box {
  width: 100%;
}

.log-mod .form-box .text-input {
  margin-bottom: 10px;
}

p,
span {
  font-size: 0.875rem !important;
}

h5,
h4 {
  font-size: 0.8125rem;
}

.goog-te-menu-value {
  color: white !important;
}

.goog-te-menu-value .goog-te-combo {
  background: #fff;
  color: #000;
  border-radius: 4px;
}

.goog-te-menu-value .goog-te-combo:focus-visible {
  outline: none;
}

body {
  top: 0 !important;
}

.skiptranslate iframe,
#goog-gt-tt {
  display: none !important;
}

.loadingCss ion-backdrop {
  --backdrop-opacity: 1;
  background-color: #f2f2f2;
}

.loadingCss .loading-wrapper {
  box-shadow: none;
}

/*blogs*/
.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 10px;
}

.blog-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  display: block;
}

.blog-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.blog-card h2,
.blog-card p {
  text-decoration: none;
  color: #000;
}

.blog-card h2 {
  font-size: 16px;
  margin: 10px 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-card p {
  font-size: 14px;
  color: #666;
  margin: 0 10px 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .blog-details{

} */
.pageContent {
  --background: url(../images/topImg1.png) no-repeat top center,
    url(../images/bottomimg1.png) no-repeat bottom center !important;
  background-attachment: fixed;
}

.remove-container {
  position: absolute;
  top: 10px;
  /* Adjust as needed */
  right: 10px;
  /* Adjust as needed */
}

.AppLandingPage {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 55px 10px; */
  height: 100%;
  z-index: 999;
  /* background: url(../images/topImg1.png) no-repeat top center, url(../images/bottomimg1.png) no-repeat bottom center !important; */
  background-attachment: fixed;
}

.AppLandingPage .logo {
  height: 100px;
}

.AppLandingPage .mapLogo {
  height: 250px;
}

.AppLandingPage .textLogo {
  height: 80px;
}

.AppLandingPage .tiles {
  display: flex;
  column-gap: 8px;
  margin-bottom: 20px;
}

.AppLandingPage .buttonArea {
  padding: 20px 0;
}

.map-container {
  background: #fff;
  border-radius: 10px;
  padding: 0 10px 10px 10px;
  margin: 20px;
  border: 1px solid black;
}

.thin-col {
  flex: 0 0 45px;
  padding-right: 20px !important;
}

.banner-carousel .carousel_img {
  height: 280px;
  object-fit: cover;
}

.deskTopHeader {
  display: none;
}

.bannerTitle {
  display: none;
}

.bannerArea {
  position: relative;
  min-height: 442px;
  background: radial-gradient(circle,
      rgba(255, 255, 255, 1) 0,
      rgba(188, 188, 187, 1) 100%);
  display: flex;
  justify-content: center;
}

.bannerArea .indiaMapColorful {
  position: absolute;
  display: flex;
  justify-content: center;
  max-width: 648px;
}

.bannerArea>img {
  padding-bottom: 28px;
}

.bottomPattern {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: url(../images/bottom-pattern.png) repeat-x bottom center !important;
  background-size: 700px !important;
}

.gridBox {
  display: none;
}

.footer {
  display: none;
}

.spacer {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.bannerLeft {
  display: none;
}

.img-card .caros-card h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.columnContainer .bg-grey {
  flex: 1;
}

.typed {
  border-radius: 24px 24px 0 0 !important;
}

.places-suggestions {
  position: absolute;
  width: 100%;
  background: #fff;
  top: 35px;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 0 0 24px 24px;
  box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
}

.resultList {
  cursor: pointer;
}

.resultList:hover {
  background: #ccc;
}

.backSlideDownImage {
  position: absolute;
  height: 100%;
  width: 100%;
  /* background: #fff; */
  background: #fff;
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
  z-index: 100;
  overflow: hidden;
}

.isAnimatedBackImage {
  opacity: 0;
  animation: slideImageDownTransition 1.5s linear;
}

.phoneCode {
  display: flex;
}

.otpModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 350px;
  border-radius: 20px;
  height: 220px;
}

@keyframes slideImageDownTransition {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.AppLandingPage img {
  height: 100%;
  object-fit: cover;
}

.AppLandingPage img.isAnimatedImage {
  transform: translateY(100%);
  animation: dropShadownTransition 1.5s linear;
}

.getStartedTransition {
  position: absolute;
  height: 100%;
  width: 100%;
  /* background: #fff; */

  z-index: 100;
  overflow: hidden;
  /* box-shadow: 0px 0px 20px 10px rgb(36 36 36 / 20%); */
}

.isAnimatedSplash {
  /* transform: translateY(100%); */
  visibility: hidden;
  animation: slideDownTransition 1.5s linear;
}

@keyframes slideDownTransition {
  0% {
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
}

@keyframes dropShadownTransition {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

.customSplashScreen {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;
  overflow: hidden;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.fade-out {
  opacity: 0;
}

.splash-logo {
  width: 150px;
  /* Initial logo size */
  /* animation: zoomInOut 6s ease-in-out; */
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }



  100% {
    transform: scale(2.5);
    /* Return to original size */
  }
}

ion-content {
  overscroll-behavior: none;
}

.safeArea {
  --padding-top: env(safe-area-inset-top) !important;
  --padding-bottom: env(safe-area-inset-bottom) !important;
}

.safeAreaPadding {
  padding-top: 60px !important;
  padding-bottom: 40px !important;
}

.settingsSafearea {
  padding-top: 80px !important;
  height: auto !important;
}

.iosModalPadding {
  padding-top: 80px !important;
}

.loginContainer {
  padding: 25px 20px;
}

.tabContainer {
  border: 1px solid #e87623;
  display: flex;
  border-radius: 10px;
  margin: 0 auto;
  overflow: hidden;
  width: 320px;
}

.tabContainer .tabName {
  color: #e87623;
  flex: 1;
  text-align: center;
  padding: 8px 6px;
  font-weight: 600;
  border-radius: 6px;
}

.tabContainer .tabName.active {
  background-color: #e87623;
  color: #fff;
}

.tabContent h2,
.tabContent .fields label {
  color: #000;
}

.tabContent h2 {
  font-weight: 900;
  font-size: 32px;
  margin-top: 28px;
}

.tabContent p {
  color: #4b4b4b;
  font-weight: 500;
}

.tabContent .fields {
  margin-bottom: 15px;
  position: relative;
}

.tabContent .fields .error-message {
  position: absolute;
  bottom: -15px;
  margin: 0;
  font-size: 10px !important;
  color: #ed6969;
}

.tabContent .fields label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
}

.tabContent .fields input {
  border-radius: 8px;
  border: 0;
  background: #e0e0e0;
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
}

.password-container {
  position: relative;
}

.password-container span {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}

.forgotPass {
  color: #ef8f43;
  font-size: 14px;
  font-weight: 500;
}

.submitBtn {
  text-align: center;
  margin-top: 20px;
}

.submitBtn button {
  border: 0;
  background: #e87623;
  color: #ffff;
  border-radius: 6px;
  width: 300px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 600;
}

.verify button {
  border: 0;
  background: #e87623;
  color: #ffff;
  border-radius: 6px;
  width: auto;
  margin-left: 20px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 500;
}

.info {
  text-align: center;
  margin: 15px 0;
}

.info p {
  color: #000;
  margin: 0;
  font-size: 12px !important;
}

.info p span {
  color: #e96a04;
  text-decoration: underline;
  font-size: 12px !important;
}

.info p strong {
  text-decoration: underline;
}

.profile-pic-wrapper {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto 20px;
}

.profile-pic-label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-placeholder {
  text-align: center;
  font-size: 14px;
  color: #888;
}

.profile-pic-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.delete-button {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(255 255 255);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
}

.delete-button img {
  width: 20px;
  height: 20px;
}

.genderRadioBtn {
  display: flex;
  gap: 8px;
}

.genderRadioBtn label {
  position: relative;
  width: 100px;
  text-align: center;
}

.genderRadioBtn label input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.genderRadioBtn label span {
  display: block;
  padding: 10px 15px;
  border-radius: 6px;
  background: #e0e0e0;
}

.genderRadioBtn label input[type="radio"]:checked+span {
  background: #e96a04;
  color: #fff;
}

.home-carousel3 .swiper .swiper-wrapper {
  justify-content: center;
}

.multiline-ellipsis p span,
.multiline-ellipsis p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.swiper-button-prev,
.swiper-button-next {
  top: 50% !important;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  border-radius: 35px;
  transform: translateY(-50%);
  margin-top: 0 !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: rgba(0, 0, 0, 0.8);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px !important;
  color: #fff;
}

.d_card.DistrictMap {
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px 20px 5px 5px;
}

.d_card.DistrictMap h4 {
  margin-bottom: 0;
}

.d_card.DistrictMap a {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.input1otp {
  border: 1px solid #e87623;
  padding: 10px 0 !important;
  width: 100%;
  text-align: center;
}

.input1gridrow {
  padding: 10px;
}

.otpCross {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
  color: #000000;
}

.forgotPasswordText {
  color: #e96a04 !important;
  font-size: 12px !important;
  margin: 5px;
}

.forgotPassword {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 675px) {

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }

  .d-forMobile.d_card h4 {
    padding-left: 25px;
  }

  .d-forMobile.d_card img {
    border-radius: 30px;
  }

  .d-forMobile.d_card {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  .d-forMobile.d_card .caros-card:not(.itinerary) img {
    height: 280px;
    width: 100%;
    object-fit: cover;
  }

  .d-forMobile.d_card .caros-card.itinerary img {
    width: 100%;
  }

  .d-forMobile.d_card .carousel-list {
    min-height: auto;
  }
}

@media (min-width: 676px) {

  .swiper-button-prev,
  .swiper-button-next {
    display: flex !important;
  }
}

@media (min-width: 768px) {
  .bannerArea>img {
    max-width: 646px;
  }
}

@media (min-width: 1025px) {
  ion-fab {
    display: none;
  }

  .columnContainer {
    display: flex;
    height: 100%;
  }

  .districtName h4 {
    border-radius: 6px 6px 6px 6px;
    cursor: pointer;
  }

  .columnContainer {
    overflow: hidden;
  }

  .footerMenu {
    display: none;
  }

  .twoCols .text-card img {
    width: 60px;
    height: 60px;
  }

  .twoCols .text-card {
    align-items: center;
  }

  .our-favourites {
    font-size: 24px;
  }

  .getStartedTransition {
    display: none;
  }

  .locationChoose .locationChooseLabel {
    font-size: 16px !important;
  }

  .columnContainer .bg-grey {
    padding: 15px 0 15px;
    overflow-y: scroll;
    width: 100%;
    height: 100vh;
    background: url(../images/topImg1.png) no-repeat top center,
      url(../images/bottomimg1.png) no-repeat bottom center !important;
  }

  .bannerLeft {
    display: block;
    width: 30%;
    position: relative;
  }

  .bannerLeft .labelDiv {
    position: absolute;
    bottom: 20px;
    padding: 12px;
    width: 100%;
    background: rgb(0 0 0 / 60%);
    color: #fff;
    text-align: center;
    font-size: 22px;
  }

  .swiper-zoom-container img {
    height: 60vh;
    width: 60vh;
    object-fit: fill;
  }

  .bannerLeft img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .bannerArea {}

  .bannerArea .indiaMapColorful {
    padding-top: 60px;
  }

  .bannerArea>img {
    max-width: 710px;
  }

  .locationChoose .locationChooseLabel {
    background: #ffffff;
    color: #000000;
  }

  .home-carousel3>div:not(.tagLine) {
    transform: none;
    /* padding-top: 70px; */
  }

  .overlapped {
    --padding-top: 0;
  }

  .inner-title {
    font-size: 36px;
    margin-bottom: 35px;
    margin-top: 40px;
  }

  .gridBox {
    display: flex;
    flex-wrap: wrap;
  }

  .gridBox .swiper-slide {
    width: 25%;
    padding: 10px;
  }

  .top-banner {
    align-items: flex-end;
    width: 715px;
    padding-top: 0 !important;
  }

  .locationChoose {
    margin-top: 40px;
  }

  .bannerTitle h2 {
    font-size: 60px;
    font-weight: 900;
  }

  .bannerTitle h3 {}

  .bannerTitle h2,
  .bannerTitle h3 {
    color: #fff;
  }

  .bannerTitle {
    display: block;
    margin-bottom: 60px;
  }

  .t-bar {
    display: none;
  }

  .deskTopHeader .locationChoose {
    display: flex;
  }

  .deskTopHeader {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    max-width: 1230px;
    margin: 0 auto;
  }

  .deskTopHeader .headerLogo img {
    height: 70px;
    cursor: pointer;
  }

  .deskTopHeader .navigationLinks {
    margin-left: auto;
  }

  .deskTopHeader .navigationLinks ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }

  .deskTopHeader .navigationLinks ul li {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .deskTopHeader .navigationLinks ul li:not(:first-child) {
    padding-left: 20px;
  }

  .deskTopHeader .navigationLinks ul li img {
    height: 20px;
    margin-bottom: 5px;
  }

  .deskTopHeader .navigationLinks ul li span {
    font-size: 12px !important;
  }

  .home-carousel2 .swiper {
    /* display: none; */
  }

  .nav_inner,
  .top-banner,
  .container,
  .home-carousel2,
  .home-carousel3>div {
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .home-carousel2 {
    margin-top: 40px;
  }

  .banner-carousel .carousel_img {
    height: 500px;
  }

  .tagLine {
    max-width: 450px !important;
    color: #fff;
  }

  .home-carousel3 {
    padding: 70px 0;
  }

  .translucent-header {
    background: rgb(255 255 255 / 30%);
    backdrop-filter: blur(2px);
  }

  .tagLine p {
    font-size: 32px !important;
  }

  .tagLine strong {
    font-size: 60px;
  }

  .home-carousel3 .swiper-slide {
    /* width: 33.333%;
    height: 300px !important; */
  }

  .map-container {
    max-width: 1230px;
    margin: 45px auto 15px;
  }

  .footer {
    display: block;
    position: relative;
    padding: 15px 0;
    text-align: center;
    background: #c6c6c6;
  }

  .footer-inner {
    max-width: 1230px;
    margin: 0 auto;
    font-weight: 700;
    padding: 0 15px;
  }

  .footer-inner .footerLinks {
    padding-top: 5px;
  }

  .footer-inner .footerLinks ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-inner .footerLinks ul li {
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
  }

  .footer-inner .footerLinks ul li span {
    cursor: pointer;
  }

  .footer-inner .footerLinks ul li span:hover {
    color: #fa4939;
  }

  .footer-inner .footerLinks ul li:not(:first-child):before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    left: -2px;
    top: 6px;
    background: #1d5d94;
    border-radius: 50%;
  }

  .padding-box {
    padding: 0;
  }

  .flex-head {
    display: block;
    position: relative;
    margin-bottom: 25px;
    padding-top: 20px;
  }

  .flex-head:before {}

  .flex-head .inner-title {
    margin: 0 !important;
    position: relative;
    z-index: 1;
    font-weight: 900 !important;
  }

  .flex-head .inner-title strong {
    display: block;
    font-size: 20px;
  }

  .tagsCloud {}

  .tagsCloud ion-item {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 40px;
    --border-color: transparent;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    box-shadow: 0px 2px 12px 1px rgb(0 0 0 / 7%);
  }

  .tagsCloud ion-item:hover {
    --background: #fa4939;
    color: #fff;
  }

  .tagsCloud ion-item:hover p {
    color: #fff;
  }

  .gridCard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .gridCard .cardItem {
    width: 33.3333%;
    padding: 10px;
  }

  .gridCard .cardItem .caros-card img,
  .img-card .caros-card:not(.itinerary) img {
    width: 100%;
    object-fit: cover;
  }

  .gridCard .cardItem .caros-card:not(.itinerary) img,
  .img-card .caros-card:not(.itinerary) img {
    height: 220px;
  }

  .horrizontal-space {
    margin-left: 10px;
    margin-right: 10px;
  }

  .multiline-ellipsis p:not(:first-child),
  .multiline-ellipsis p span:not(:first-child) {
    display: none;
  }

  .home-carousel2 .react-multi-carousel-item,
  .home-carousel2 .swiper-slide {
    /* height: 280px !important; */
  }

  .white-ion-content-bg {
    --background: #fff !important;
  }

  .swiper-pagination {
    bottom: -2px !important;
  }

  .swiper-pagination-bullet {
    height: 12px !important;
    width: 12px !important;
  }

  .swiper-pagination-bullet-active {
    background: #000 !important;
  }

  .text-desktop-center {
    text-align: center !important;
  }

  .forDesktop .swiper {
    padding-bottom: 15px;
  }

  .d_card.image img {
    height: 280px !important;
  }

  .detailedCarousel {
    min-height: 300px;
    z-index: 1;
  }

  .detailedCarousel img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .flexDisplay {
    display: flex;
    align-items: flex-end;
  }

  .flexDisplay .flexCol {
    width: 50%;
  }

  .bannerCarousel {
    border-radius: 10px;
    overflow: hidden;
  }

  .forMbl {
    display: none !important;
  }

  .react-tabs {
    display: flex;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 30px;
  }

  .react-tabs .react-tabs__tab-list {
    width: 250px;
    list-style: none;
    padding: 10px;
    margin: 0;
    border-right: 1px solid rgb(87 87 87 / 20%);
  }

  .react-tabs .react-tabs__tab-list .react-tabs__tab {
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 10px;
  }

  .react-tabs .react-tabs__tab-list .react-tabs__tab:not(:first-child) {
    margin-top: 5px;
  }

  .react-tabs .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected,
  .react-tabs .react-tabs__tab-list .react-tabs__tab:hover {
    background: #fa4939;
    color: #fff;
  }

  .react-tabs .react-tabs__tab-list .react-tabs__tab:focus-visible {
    outline: none;
  }

  .react-tabs .tabPanelContainer {
    flex: 0 0 auto;
    width: calc(100% - 250px);
  }

  .react-tabs .tabPanelContainer .react-multi-carousel-list {
    width: 500px;
  }

  .react-tabs .tabPanelContainer .react-multi-carousel-list img {
    height: 280px;
  }

  .react-tabs .tabPanelContainer .react-tabs__tab-panel {}

  .d_card {
    /* padding-right: 0;
    padding-left: 0; */
    margin-left: 0;
    margin-right: 0;
    background: #fff;
  }

  .react-multi-carousel-list img {
    object-fit: cover;
  }

  .text-card {
    border-radius: 10px !important;
    margin-left: 0;
    background-color: #fff;
    margin: 0 10px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }

  .historyArea .text-card {
    background-color: transparent;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }

  .d_card h4 {
    margin-bottom: 10px;
    /* padding-left: 10px; */
  }

  .AppLandingPage {
    padding: 15px 10px;
  }

  .AppLandingPage .logo {
    height: 120px;
  }

  .AppLandingPage .mapLogo {
    height: 300px;
  }

  ion-modal .AppLandingPage .mapLogo {
    height: 180px;
  }

  ion-modal .AppLandingPage .tiles {
    padding: 0 78px;
  }

  ion-modal .logincard {
    height: 120px;
  }

  ion-modal .AppLandingPage .textLogo {
    height: 60px;
  }

  .AppLandingPage .textLogo {
    height: 100px;
  }

  .logincard {
    height: 160px;
  }

  .placeName {
    font-size: 1.25rem !important;
  }

  .d_card p,
  .img-card .caros-card h5 {
    font-size: 14px !important;
  }

  .modalCustom {
    --width: 800px;
    --height: 800px;
  }
}

.hasBannerOverlay:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: linear-gradient(180deg, transparent 0%, rgb(0 0 0 / 65%) 75%);
  pointer-events: none;
  z-index: 1;
}

.text-card ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
  list-style-type: none !important;
}

.text-card ul li {
  padding-left: 18px;
  position: relative;
  text-align: left !important;
}

.text-card ul li span {
  text-align: left !important;
}

.text-card ul li:not(:first-child) {
  margin-top: 8px;
}

.text-card ul li:before {
  content: "";
  position: absolute;
  width: 10px;
  left: 0;
  top: 1px;
  height: 10px;
  background: url("../images/bullet.png");
  background-size: 10px;
  background-repeat: no-repeat;
}

@media (max-width: 1024px) {
  .d_card.ThingsToDo .carousel-list {
    min-height: 150px;
  }

  ion-content.backgroundGraphics {
    --background: url(../images/topImg1.png) no-repeat top center,
      url(../images/bottomimg1.png) no-repeat bottom center !important;
  }

  .columnContainer .bg-grey {
    width: 100%;
  }

  .tagsCloud ion-item {
    padding: 8px 4px;
    background: #fff;
    box-shadow: 0px 2px 12px 1px rgb(0 0 0 / 7%);
    border-radius: 20px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  .tagsCloud ion-item:not(:first-child) {
    margin-top: 10px;
  }

  .header-md::after {
    display: none;
  }

  .twoCols .text-card {
    flex-direction: column;
  }

  .twoCols .text-card ul li,
  .twoCols .text-card ul li span {
    font-weight: 600;
    font-size: 12px !important;
    line-height: 1;
    /* word-break: break-all; */
    text-align: left !important;
  }

  .twoCols h4 {
    font-size: 12px;
  }

  .carousel-list {
    min-height: 280px;
  }

  .swiper-zoom-container img {
    height: 50vh;
    width: 50vh;
    object-fit: fill;
  }

  .forDesktop {
    display: none;
  }

  .flex-head {
    background: none !important;
  }

  .react-tabs {
    display: none;
  }
}

@media (max-width: 475px) {
  .home-carousel3 .swiper-slide {
    /* width: 33.333%; */
  }
}