ion-content {
    --background: #f3f3f3 !important;
}

#background-content {
    background: #f3f3f3 !important;
}

.padding-box {
    padding: 20px 5px;
}

.d-flex {
    display: flex;
}

.color-white {
    color: #fff;
}

.title-txt {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.sub-title {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 7px;
    margin-top: 0;
}

.inner-title {
    color: #121212;
    font-size: 14px;
    font-weight: 600;
}

.center-title {
    color: #121212;
    font-size: 14px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
}

.link-title {
    font-size: 13px;
    color: #FA4939;
    text-decoration: underline;
    font-weight: 600;
    margin-right: 10%;
}

.line-text {
    color: #52ADF5 !important;
    text-decoration: underline;
}

.flex-head {
    display: flex;
    justify-content: space-between;

    .inner-title {
        font-weight: 600;
        margin-left: 11px;
    }
}

.bg-grey {
    background-color: #f3f3f3;
}

.border-radius {
    border-radius: 25px;
}

.t-bar {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
}

.s-bar {
    .searchbar-input-container {
        height: 41px;
    }

    .searchbar-input {
        background-color: #fff;
        font-size: 14px;
        border-radius: 10px;
    }
}

.card {
    background: #fff;
    text-align: center;
    border-radius: 25px;
    padding: 10px 0;
    margin-top: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blue-bg {
    background-color: #52ADF5;
}

.orng-bg {
    background-color: #FFBB56;
}

.pink-bg {
    background-color: #F57EA6;
}

.purple-bg {
    background-color: #A981F2;
}

.green-bg {
    background-color: #6DE382;
}

.pos-box {
    position: relative;
}

.home-sec {
    .border-radius {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
    }
}

.info-sec {
    .card {
        padding: 0;
    }

    .title-txt {
        margin-top: 0;
    }
}

.fil-icon {
    background: #fff;
    width: 10%;
    height: 10%;
    border-radius: 5px;
    padding: 5px 5px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

// Form-Input //
.form-box {
    width: 100%;

    .text-input {
        margin-bottom: 20px;

        .MuiInput-root.MuiInput-underline {
            border: 1px solid #fff;
            border-radius: 10px;
            padding: 2px 10px;
            font-size: 14px;
            background: rgba(255, 255, 255, 0.1);

            &::before {
                border-bottom: 0;
                border-left: 1px solid #fff;
                bottom: 11px;
                left: 37px;
            }
        }

        #input-with-icon-textfield {
            padding-left: 7px;
            height: 2em;
            color: #fff;
            // &::after{
            //     border-bottom: 0;
            // }
        }

        svg.MuiSvgIcon-root {
            color: #fff;
            font-size: 20px;
        }
    }
}

// Button // 
.MuiButton-root.MuiButton-Primary {
    &.white-btn {
        border: 1px solid #fff;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 600;
        border-radius: 10px;
        background: #fff;
        padding: 8px;
        color: #121212 !important;
    }
}

.zoom-btn {
    position: absolute;
    bottom: 30px;
    right: 20px;
    background: #FA4939;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    border-radius: 30px;
    box-shadow: 4px 12px 22px rgba(255, 0, 0, 0.3);

    img {
        width: 23px;

        &:first-child {
            border-bottom: 1px solid #f3f3f3;
            padding-bottom: 6px;
        }

        &:last-child {
            padding-top: 6px;
        }
    }
}

// Logo // 
.top-logo {
    position: absolute;
    left: 0;
    right: 0;
    top: 5px;
    text-align: center;
    margin: auto;

    img {
        width: 20%;
    }

    h3 {
        font-size: 15px;
        color: #fff;
        text-transform: uppercase;
        margin: 0;
    }
}

// Login // 
.login-sec {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4%;
    width: 85%;
    margin: auto;

    h4 {
        color: #fff;
        font-size: 15px;
        margin-bottom: 15px;
    }
}

.login-bg {
    background-image: url(../../public/assets/icon/LoginBg.jpg);
    position: relative;
    background-position: 100% 58%;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 100%;
}

// Navbar // 
.top-nav {
    display: flex;
    justify-content: space-between;
    background-color: #FA4939;
    padding: 5px 10px;

    svg.MuiSvgIcon-root {
        height: 50px;
    }
}

// Banner // 
.top-banner {
    position: relative;
    background-color: #f3f3f3;

    img {
        width: 100%;
    }

    .banr-box {
        position: absolute;
        bottom: 12px;
        left: 0;
        right: 0;
    }
}

// View Map // 
.map-sec {
    .top-banner {
        .banr-box {
            position: absolute;
            bottom: 30px;
            left: 0;
            right: 0;

            .title-txt {
                margin-top: 0;
            }
        }
    }

    .s-bar {
        position: absolute;
        bottom: -27px;

        .searchbar-input-container {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
        }
    }
}

// Carousel // 
.caros-card {
    text-align: center;
    border-radius: 16px;
    padding: 10px;
    height: 100%;
    width: 94%;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);

    img {
        width: 43px;
        height: auto;
    }

    h5 {
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.map-carousel {
    background: #fff;
    border-top-right-radius: 60px;

    .padding-box {
        padding-right: 0;
        width: 120%;
    }

    .react-multi-carousel-item {
        margin-bottom: 10px;
        margin-top: 5px;
    }

    .caros-card {
        width: 90%;
        background-color: #fff;
        box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.2);
        padding: 0;
        margin-left: 5px;

        img {
            width: 100%;
            height: 100px;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }

        h5 {
            color: #121212;
        }
    }
}

.home-carousel {
    padding: 20px 0 0 25px;
    width: 118%;

    .react-multi-carousel-item {
        margin-bottom: 5px;
    }
}

.place-carousel {
    .padding-box {
        padding-right: 0;
    }

    .caros-card {
        box-shadow: unset;
        padding: 0;
        text-align: left;

        h4 {
            color: #121212;
            font-size: 15px;
            margin-top: 10px;
            margin-left: 10px;
            font-weight: 600;
        }

        img {
            width: 100%;
            height: 247px;
            border-radius: 25px;
            margin-bottom: 25px;
        }

        h5 {
            color: #121212;
            font-size: 13.9px;
            text-align: left;
            font-weight: 600;
        }

        p {
            font-size: 12px;
            line-height: 1.3;
        }
    }

    .react-multi-carousel-list {
        width: 187%;
    }

    .react-multi-carousel-dot-list {
        bottom: unset;
        top: 35%;
        left: 22%;
        right: unset;
    }

    .react-multi-carousel-dot button {
        border: 0;
        background: #ccc;
        width: 9px;
        height: 9px;
    }

    .react-multi-carousel-dot--active button {
        background: #FA4939;
    }
}

.view-carousel {
    .caros-card {
        background-color: #fff;
        padding: 0;
        margin-left: 5px;
        border-radius: 25px;

        img {
            width: 100%;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            height: 120px;
        }

        h5 {
            color: #121212;
            text-align: left;
            font-size: 13px;
            margin: 10px 20px 7px;
            font-weight: 600;
        }

        .flex-head {
            margin: 0 15px 15px;
        }

        .MuiSvgIcon-root {
            color: #FA4939;
            font-size: 18px;
        }

        span {
            display: flex;

            h6 {
                font-size: 12px;
                margin: 0;
                line-height: 1.6;
            }
        }
    }

    .padding-box {
        padding-right: 0;
    }

    .react-multi-carousel-item {
        margin-bottom: 10px;
    }

    .react-multi-carousel-list {
        width: 170%;
    }
}

// Table  // 
.table-info {

    th,
    td {
        &.MuiTableCell-root {
            font-size: 12px;
            text-align: left;
            border-bottom: 0;
            padding: 16px 12px;

            &:not(:last-child) {
                border-right: .9px solid rgba(224, 224, 224, 1);
            }
        }
    }
}

// Gallery // 
.img-card {
    .inner-title {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .caros-card {
        margin-left: 0;
        background-color: #fff;
        padding: 0;
        width: 100%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        img {
            width: 100%;
            height: 120px;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }

        h5 {
            color: #121212;
            text-align: left;
            font-size: 13px;
            margin: 10px 20px 7px;
            font-weight: 600;
        }

        .flex-head {
            padding-bottom: 10px;
            margin: 0 10px 18px;
        }

        span {
            display: flex;

            h6 {
                font-size: 12px;
                margin: 0;
                line-height: 1.6;
            }
        }

        .MuiSvgIcon-root {
            color: #FA4939;
            font-size: 18px;
        }
    }
}

.img-gallery {
    .MuiImageListItem-img {
        border-radius: 16px;
    }

    .MuiImageListItemBar-root {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        text-align: center;
    }

    .MuiImageListItemBar-titleWrap {
        padding: 3px;
    }

    .MuiImageListItemBar-title {
        font-size: 12px;
    }

    .MuiImageListItemBar-subtitle {
        font-size: 10px;
    }
}

.map-box {
    padding: 20px 0 0 25px;
}

.city-list-box {
    ion-item {
        --padding-start: 20px;
        --background: #f3f3f3;
        margin: 0 30px;
        --padding-end: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        &:hover {
            --background: #FA4939;
            color: #fff;
        }
    }
}

.img-caros {
    width: 100% !important;

    img {
        width: 100%;
        padding: 5px;
    }

    .react-multiple-carousel__arrow:hover {
        background: #FA4939 !important;
    }

    .react-multiple-carousel__arrow {
        background: rgba(244, 74, 59, 0.62) !important;
    }
}

.wrap-head {
    padding-bottom: 0;
    margin-bottom: 0;
    margin-left: 14px;
    margin-right: 14px;
    display: flex;
    justify-content: space-between;
}

.desc-box {
    margin: 10px 15px;
    color: black;
    height: 80px;
    overflow: auto;

    p {
        font-size: 10px;
        text-align: left;
        padding-bottom: 10px;
        margin: 0;
    }
}