ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
}

ion-menu.md ion-list {
  padding: 0px 0;
  /* height: 100%; */
}

.sideMenuBody {
  --background: #fff !important;
}

.sidemenuFooter {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sidemenuFooter .footerLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 8px;
}

.sidemenuFooter .footerLinks ion-menu-toggle {
  position: relative;
  padding: 0 5px;
  line-height: 1;
  color: rgb(0 0 0 / 55%);
  font-size: 14px;
  font-weight: 500;
}

.sidemenuFooter .footerLinks ion-menu-toggle:not(:first-child):before {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  background: rgb(0 0 0 / 55%);
  left: 0;
}

.underline {
  background: rgb(0 0 0 / 55%);
  width: 110px;
  height: 1px;
  margin-top: 15px;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

#inbox-list {
  overflow-y: scroll
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 0;
  padding: 0 20px;
}

.Menu {
  padding: 10px 0 12px;
  margin: 0 40px 15px;
  border-bottom: 1px solid #000;
  font-weight: 600;
}

.closeSideMenu {
  margin: 0 40px 15px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  padding: 8px 0;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
  width: 30px;
  background-color: ghostwhite;
  border-radius: 50%;
  height: 30px;
  padding: 5px;
  box-shadow: 1px 3px #bfbfbf8a;
}

ion-menu.md ion-item ion-label,
ion-menu.ios ion-item ion-label {
  font-weight: 500;
  font-weight: 600;
  font-family: sans-serif !important;
  font-size: 14px !important;
  color: #1a1a1a !important;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 0 20px;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  padding: 8px 0;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 0px;
  padding-right: 0x;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

.item-has-start-slot {
  background-color: #082626 !important;
}